<template>
  <div class="mem-view codes-list">
    <div class="mem-title">{{ $t("my_codes") }}</div>

    <!-- loading -->
    <template v-if="!isLoading">
      <!-- active codes -->
      <template>
        <div v-if="!showActiveCodes" class="codes-not-found page-section">
          <div class="section-image">
            <span class="material-icons pool-icon"> pool </span>
          </div>
          <span class="section-text">
            {{ $t("my_codes_empty_list") }}
          </span>
        </div>

        <div v-else class="codes-section page-section">
          <active-code-card
            v-for="(code, index) in activeCodes"
            :key="index"
            :code="code"
          />
        </div>
      </template>

      <div v-if="showUpsell" class="upsell-section page-section">
        <div class="section-titile">{{ $t("my_codes_upsell_title") }}</div>
        <div class="upsell-grid">
          <upsell-card
            v-for="(upsell, index) in upsellItems"
            :key="index"
            :data="upsell"
          />
        </div>
      </div>

      <div v-if="showExpiredCodes" class="past-codes page-section">
        <v-expansion-panels
          v-model="panel"
          accordion
          multiple
          active-class="mem-expansion-panels"
        >
          <v-expansion-panel class="mem-expansion-panel">
            <v-expansion-panel-header class="mem-panel-header">
              {{ $t("past_codes") }}
            </v-expansion-panel-header>
            <v-expansion-panel-content class="mem-panel-content">
              <past-code-card
                v-for="(code, index) in expiredCodes"
                :key="index"
                :code="code"
              />
            </v-expansion-panel-content>
          </v-expansion-panel>
        </v-expansion-panels>
      </div>
    </template>
    <div v-else class="list-loading">
      <div class="loading-line">
        <div class="animated"></div>
      </div>
      <div class="loading-line"></div>
      <div class="loading-line">
        <div class="animated"></div>
      </div>
    </div>
  </div>
</template>
<script>
import config from "@/config";
import { mapGetters, mapActions } from "vuex";

export default {
  components: {
    "active-code-card": () => import("./CardActiveCode.vue"),
    "past-code-card": () => import("./CardPastCode.vue"),
    "upsell-card": () => import("./CardUpsell.vue"),
  },
  data: () => ({
    isLoading: true,
    panel: [],
    upsellItems: [],
  }),
  computed: {
    ...mapGetters(["activeCodes", "expiredCodes", "isLoadedStatus"]),
    showActiveCodes() {
      return this.activeCodes?.length > 0;
    },
    showExpiredCodes() {
      return this.expiredCodes?.length > 0;
    },
    showUpsell() {
      return this.upsellItems?.length > 0;
    },
  },
  methods: {
    ...mapActions(["getCodes", "getContentfulEntry"]),
    async fetchContentfulEntry() {
      try {
        return await this.getContentfulEntry(config.contentful.entries.passes);
      } catch (contentfulError) {
        console.log("Contentful Error:", contentfulError);
        throw contentfulError;
      }
    },
  },
  watch: {
    "isLoadedStatus.userData": {
      immediate: true,
      async handler(isLoaded) {
        if (!isLoaded) return;

        this.isLoading = true;
        // Codes
        try {
          await this.getCodes();
          if (this.activeCodes?.length === 0) {
            // Contentful request
            this.upsellItems = await this.fetchContentfulEntry();
          }
        } catch (error) {
          if (error.type !== "contentful") {
            this.upsellItems = await this.fetchContentfulEntry();
          } else {
            console.log("Error fetching codes:", error);
          }
        } finally {
          this.isLoading = false;
        }
      },
    },
  },
  async mounted() {},
};
</script>
<style lang="scss" scoped>
$section-width: 700px;
.codes-list {
  .mem-title {
    @include elementMarginBottom("M");
  }
  .page-section {
    margin-top: 1px;
    max-width: $section-width;
    position: relative;
    & + .page-section::before {
      content: "";
      display: block;
      height: 1px;
      width: calc(100% - 44px);
      background-color: #454444;
      position: absolute;
      top: -1px;
      left: 22px;
    }
  }
  .codes-not-found {
    min-height: 316px;
    background-color: #0f0d0d;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    @media screen and (max-width: $mobile) {
      padding: 60px 42px 0 42px;
      justify-content: flex-start;
    }
    .section-image {
      display: flex;
      flex-direction: column;
      align-content: center;
      justify-content: center;
      height: 120px;
      width: 120px;
      background-color: #b6b6b6;
      border-radius: 120px;
      margin-bottom: 24px;
      @media screen and (max-width: $mobile) {
        height: 80px;
        width: 80px;
      }
      .pool-icon {
        text-align: center;
        color: #030303;
        font-size: 48px;
      }
    }
    .section-text {
      max-width: 400px;
      text-align: center;

      font-size: 18px;
      font-style: normal;
      font-weight: 700;
      line-height: 28px;
      @media screen and (max-width: $mobile) {
        max-width: 290px;
      }
    }
  }
  .codes-section {
    background-color: #0f0d0d;
    padding: 24px 30px;
    @media screen and (max-width: $mobile) {
      background-color: inherit;
      padding: 0 8px 24px;
    }
  }
  .upsell-section {
    // display: none;
    background-color: #0f0d0d;
    @media screen and (max-width: $mobile) {
      padding-right: 20px;
      padding-left: 20px;
    }
    .upsell-grid {
      display: grid;
      grid-template-columns: 1fr 1fr;
      gap: 18px;
      @media screen and (max-width: $mobile) {
        grid-template-columns: 1fr;
      }
    }
  }
  .section-titile {
    padding-top: 24px;
    padding-bottom: 28px;
    font-size: 22px;
    font-style: normal;
    font-weight: 700;
    line-height: 28px; /* 127.273% */
  }
  .past-codes {
    .mem-expansion-panels,
    .mem-expansion-panel {
      background-color: #0f0d0d;
    }
    .mem-panel-header {
      padding: 25px 30px;
      font-size: 22px;
      font-style: normal;
      font-weight: 700;
      line-height: 28px;
      @media screen and (max-width: $mobile) {
        font-size: 18px;
        padding-right: 20px;
        padding-left: 20px;
      }
      ::v-deep(.v-icon) {
        font-size: 48px;
      }
    }
    .mem-panel-content {
      ::v-deep(.v-expansion-panel-content__wrap) {
        padding-right: 30px;
        padding-left: 30px;
        @media screen and (max-width: $mobile) {
          padding-right: 8px;
          padding-left: 8px;
        }
      }
    }
  }
  .list-loading {
    display: flex;
    flex-direction: column !important;
    justify-content: flex-start !important;
    overflow: hidden;
    max-width: $section-width;
    @include elementMarginBottom("M");

    .loading-line {
      height: 136px;
      background-color: #020000;
      position: relative;
      overflow: hidden;

      .animated {
        -webkit-animation: loading-line 2s infinite;
        animation: loading-line 2s infinite;
        content: " ";
        height: 100%;
        left: 0;
        position: absolute;
        right: 0;
        top: 0;
        // -webkit-transform: translateX(-100%);
        // transform: translateX(-100%);
        background: linear-gradient(
          110deg,
          rgba(0, 0, 0, 0),
          rgba(255, 255, 255, 0.05),
          rgba(255, 255, 255, 0.15) 70%,
          rgba(255, 255, 255, 0.05),
          rgba(0, 0, 0, 0)
        );
      }
    }
  }
}
</style>